import {testimonial} from '../../assets/images/index.js';
import { Row, Col  } from 'react-bootstrap';
import FeatherIcon from 'feather-icons-react';
import './style.scss';

const Testimonials = () => {
  return (
    <div className="testimonial-slide">
      <Row className="mb-4">
        <Col lg={6}>
          <h4 className="title mb-lg-0">Amazing Solution!</h4>
        </Col>
        <Col lg={6}>
          <div className="rating text-lg-end">
            <FeatherIcon className="star" icon="star"/>
            <FeatherIcon className="star" icon="star"/>
            <FeatherIcon className="star" icon="star"/>
            <FeatherIcon className="star" icon="star"/>
            <FeatherIcon className="star active" icon="star"/>
          </div>
        </Col>
      </Row>
      <p>“Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, 
        consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. 
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet.</p>
        <div className="testimonial-profile">
          <div className="image">
            <div className="avtar" style={{backgroundImage:'url('+testimonial+')'}}></div>
          </div>
          <div className="content">
            <div className="text">
              <h6 className="name">Anna Parker</h6>
              <p className="position">Co-Founder Orbit Enterprises</p>
            </div>
          </div>
        </div>
    </div>
  );
}

export default Testimonials;
