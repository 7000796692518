import React from "react";
import { girl_a, logo, shape_1, shape_2, shape_3, shape_4 } from '../../assets/images/index.js';
import '../../assets/style/style.scss';
import { Container, Row, Col, Card, Image, Form, Button } from 'react-bootstrap';
import { connect } from "react-redux";
import emailjs from '@emailjs/browser';
import {
  forgotPassword,
} from "../../redux/actions/authAction.js";
import Toasts from "../../component/Toasts";
import { ToastContainer } from 'react-toastify';

class ForgotPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      email: "",
    };
  }
  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };
  handleForgetPassword = async () => {
    var chars = "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
    var passwordLength = 12;
    var RendomPass = "";
    for (var i = 0; i <= passwordLength; i++) {
      var randomNumber = Math.floor(Math.random() * chars.length);
      RendomPass += chars.substring(randomNumber, randomNumber + 1);
    }
    try {
      if (this.state.email !== "") {
        await this.props.setForgotPassword(this.state.email, RendomPass);
      }
      // Toasts("password send successfully", "success");
    } catch (error) {
      Toasts(error, "error");
    }

  };
  componentDidUpdate(Props) {
    if (this.props.ForgotPassword !== Props.ForgotPassword) {
      if (this.props.ForgotPassword?.success == 200) {        
        // const serviceID = "service_90t5fto";
        // const templateID = "template_idmxic9";
        // const public_key = "6Grd8FbNR555Ujun3";
        const params = {
          code: this.props.ForgotPassword?.data?.password,
          email: this.state.email,
        };
        emailjs.send(process.env.REACT_APP_MAIL_SERIVICE_ID, process.env.REACT_APP_MAIL_TEMPLATE_ID, params, process.env.REACT_APP_MAIL_PUBLIC_KEY).then(
          function (response) {
            console.log("MAIL SEND SUCCESS!", response.status, response.text);
            Toasts("password send successfully", "success");
          },
          function (error) {
            console.log("FAILED...", error);
          }
        );
      }
    }
  }
  handleLoginClick = () => {
    this.props.navigate("/login");
  }
  handleCreateClick = () => {
    this.props.navigate('/createaccount');
  }
  render() {
    return (
      <>
        <div id="log-in-forgot" className="d-flex align-items-center">
          <Container>
            <Card className="br-30 box-shadow-1">
              <Card.Body className="p-lg-5 p-4">
                <Row>
                  <Col lg={6}>
                  </Col>
                  <Col lg={6}>
                    <div className="mb-lg-5 mb-4 pb-lg-4">
                      <Image className="" src={logo} fluid />
                    </div>
                    <h1 className="display-1 title mb-4"> Forgot <span>Password</span></h1>
                    <p className="mb-4 f-black">Type your email address below and we’ll send you <br /> informations on how to recover your password.</p>
                    <Form.Group className="mb-4">
                      <Form.Label className="mb-3">Email</Form.Label>
                      <Form.Control
                        name='email'
                        type='email'
                        value={this.state.email}
                        onChange={this.handleChange}
                        placeholder="www.my@gmail.com" />
                    </Form.Group>
                    <Button className="mb-4 mb-lg-5"
                      variant="green"
                      onClick={this.handleForgetPassword}
                      size="lg">{this.state.isLoading
                        ? "Loading"
                        : "Send email"}</Button>
                    <Button className="mb-2 d-table p-0" variant="link" onClick={this.handleLoginClick}>Login</Button>
                    <Button className="d-table p-0" variant="link" onClick={this.handleCreateClick}>Create Account</Button>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Container>
          <Image className="girl d-none d-lg-block" src={girl_a} fluid />
          <Image src={shape_1} fluid className="shape-1" />
          <Image src={shape_2} fluid className="shape-2" />
          <Image src={shape_3} fluid className="shape-3" />
          <Image src={shape_4} fluid className="shape-4" />
          <Image src={shape_3} fluid className="shape-5" />
        </div>
        <ToastContainer />
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  ForgotPassword: state.auth.ForgotPassword,
  loading: state.auth.loading,
});

const mapDispatchToProps = (dispatch) => {
  return {
    setForgotPassword: (params, pass) => dispatch(forgotPassword(params, pass)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);
