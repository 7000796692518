import * as types from "../actionTypes";

export const signupAction = (field) => async (dispatch) => {
  // console.log("fields -> ", field);
  try {
    dispatch({ type: types.LOADING, payload: true });
    const card = {
      number: field.card_number,
      exp_month: field.exp_month,
      exp_year: field.year,
      cvc: field.code,
    };
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        amount: 1200,
        card: card,
        domain: field.domain,
        email: field.email,
        first_name: field.first_name,
        last_name: field.last_name,
        password: field.password,
      }),
    };
    await fetch(
      `${process.env.REACT_APP_PUBLIC_URL}/auth/create_account`,
      requestOptions
    )
      .then(function (response) {
        return response.json();
      })
      .then(function (body) {
        dispatch({ type: types.SIGNUP_SUCCESS, payload: body });
        dispatch({ type: types.LOADING, payload: false });
      });
  } catch (error) {
    dispatch({ type: types.LOADING, payload: false });
  }
};
export const LoginAction = (field) => async (dispatch) => {
  try {
    dispatch({ type: types.LOADING, payload: true });
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        email: field.email,
        password: field.password,
      }),
    };
    // console.log("body -> ", requestOptions);
    await fetch(
      `${process.env.REACT_APP_PUBLIC_URL}/auth/login_account`,
      requestOptions
    )
      .then(function (response) {
        // console.log("res -> ", response);
        return response.json();
      })
      .then(function (body) {
        localStorage.setItem("UID", JSON.stringify(body.data._id));
        sessionStorage.setItem("Token", body.accessToken);
        dispatch({ type: types.LOGIN_SUCCESS, payload: body });
        dispatch({ type: types.LOADING, payload: false });
      });
  } catch (error) {
    dispatch({ type: types.LOADING, payload: false });
  }
};
export const forgotPassword = (email, password) => async (dispatch) => {
  try {
    dispatch({ type: types.LOADING, payload: true });
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        email: email,
        password: password,
      }),
    };
    // console.log("body -> ", requestOptions);
    await fetch(
      `${process.env.REACT_APP_PUBLIC_URL}/auth/forgot_password`,
      requestOptions
    )
      .then(function (response) {
        // console.log("res -> ", response);
        return response.json();
      })
      .then(function (body) {
        console.log("res -> ", body);
        sessionStorage.removeItem("Token");
        dispatch({ type: types.FORGOT_PASSWORD, payload: body });
        dispatch({ type: types.LOADING, payload: false });
      });
  } catch (error) {
    dispatch({ type: types.LOADING, payload: false });
  }
};
export const setUserInfo = (field, id) => async (dispatch) => {
  try {
    dispatch({ type: types.LOADING, payload: true });
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        email: field.email,
        first_name: field.first_name,
        last_name: field.last_name,
        mobile_no: field.mobile_no,
        userid: id,
      }),
    };
    await fetch(
      `${process.env.REACT_APP_PUBLIC_URL}/auth/update_user_info`,
      requestOptions
    )
      .then(function (response) {
        // console.log("res -> ", response);
        return response.json();
      })
      .then(function (body) {
        //  console.log("res -> ", body);
        dispatch({ type: types.SET_USERINFO, payload: body });
        dispatch({ type: types.LOADING, payload: false });
      });
  } catch (error) {
    dispatch({ type: types.LOADING, payload: false });
  }
};
export const GetUserInfo = (userID) => async (dispatch) => {
  // console.log("user id -----> ", userID)
  try {
    dispatch({ type: types.LOADING, payload: true });
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        userid: userID,
      }),
    };
    await fetch(
      `${process.env.REACT_APP_PUBLIC_URL}/auth/get_userinfo`,
      requestOptions
    )
      .then(function (response) {
        return response.json();
      })
      .then(function (data) {
        dispatch({ type: types.GET_USERINFO, payload: data });
        dispatch({ type: types.LOADING, payload: false });
      });
  } catch (error) {
    dispatch({ type: types.LOADING, payload: false });
  }
};
export const setDomain = (field, id) => async (dispatch) => {
  try {
    dispatch({ type: types.LOADING, payload: true });
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        userid: id,
        domain: field,
      }),
    };
    await fetch(
      `${process.env.REACT_APP_PUBLIC_URL}/auth/add_domain`,
      requestOptions
    )
      .then(function (response) {
        return response.json();
      })
      .then(function (body) {
        dispatch({ type: types.SET_DOMAIN, payload: body });
        dispatch({ type: types.LOADING, payload: false });
      });
  } catch (error) {
    dispatch({ type: types.LOADING, payload: false });
  }
};
