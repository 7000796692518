import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { useLocation, useNavigate } from "react-router";
import {
  Home,
  About,
  CaseStudy,
  Testimonial,
  Test,
  CreateAccount,
  LogIn,
  ForgotPassword,
  Dashboard,
  TestInbox,
  UserInfo
} from "../container";

function MainRouter() {
  let navigate = useNavigate();
  return (
    <Routes>
      <Route path='/login' element={<LogIn navigate={navigate} />} />
      <Route exact path='/'
        element={
          localStorage.getItem("UID") == undefined ? (
            <LogIn navigate={navigate} />
          ) : (
            <Home navigate={navigate} />
          )
        }
      />
      <Route path='/createaccount' element={<CreateAccount navigate={navigate}/>} />
      <Route path='/About'
        element={
          <PrivateRoute>
            <About navigate={navigate} />
          </PrivateRoute>
        }
      />
      <Route path='/casestudy'
        element={
          <PrivateRoute>
            <CaseStudy navigate={navigate} />
          </PrivateRoute>
        }
      />
      <Route path='/testimonial'
        element={
          <PrivateRoute>
            <Testimonial />
          </PrivateRoute>
        }
      />
      <Route path='/forgot-password' element={<ForgotPassword navigate={navigate}/>} />
      <Route path='/test'
        element={
          <PrivateRoute>
            <Test />
          </PrivateRoute>
        }
      />
      <Route path='/dashboard'
        element={
          <PrivateRoute>
            <Dashboard />
          </PrivateRoute>
        }
      />
      <Route path='/test-inbox'
        element={
          <PrivateRoute>
            <TestInbox />
          </PrivateRoute>
        }
      />
      <Route path='/user-info'
        element={
          <PrivateRoute>
            <UserInfo />
          </PrivateRoute>
        }
      />
    </Routes>
  );
}
const PrivateRoute = (props) => {
  const { children } = props;
  const isLoggedIn = localStorage.getItem("UID") ? true : false;
  const location = useLocation();
  return isLoggedIn ? (
    <>{children}</>
  ) : (
    <Navigate
      replace={true}
      to='/login'
      state={{ from: `${location.pathname}${location.search}` }}
    />
  );
};
export default MainRouter;