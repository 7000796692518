import { logo_icon } from '../../assets/images/index.js';
import { Navbar, Nav, Image } from 'react-bootstrap';
import { FiActivity, FiUser, FiLogOut,FiMail } from "react-icons/fi";
import './style.scss';
import { Link, useNavigate } from "react-router-dom";
const Sidebar = () => {
  return (
    <div className='sidebar d-flex flex-column flex-shrink-0 bg-light'>
      <Navbar.Brand href="/">
        <Image src={logo_icon} fluid />
      </Navbar.Brand>
      <Nav className='nav-flush flex-column mb-auto text-center'>
        <Nav.Item>
          <Nav.Link >
            <Link to={'/dashboard'}>
              <FiActivity />
            </Link>
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link>
            <Link to={"/user-info"}>
              <FiUser />
            </Link>
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link>
            <Link to={"/test-inbox"}>
              <FiMail />
            </Link>
          </Nav.Link>
        </Nav.Item>
      </Nav>
      <Nav className='nav-flush flex-column text-center'>
        <Nav.Item>
          <Nav.Link>
            <Link to={"/login"}>
              <FiLogOut />
            </Link>
          </Nav.Link>
        </Nav.Item>
      </Nav>
    </div>
  );
}

export default Sidebar;
