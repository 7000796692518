import { avatar } from '../../assets/images/index.js';
import { Container, Row, Col } from 'react-bootstrap';
import { FiSearch, FiBell } from "react-icons/fi";
import './style.scss';
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from 'react';
import { GetUserInfo } from '../../redux/actions/authAction'

const Topbar = () => {
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.cardReducer.loading);
  const user = useSelector((state) => state.auth.UserInfo);
  useEffect(
    async () => {
      await dispatch(GetUserInfo(JSON.parse(localStorage.getItem("UID"))));
    },
    []
  );
  return (
    <div className="topbar">
      <Container fluid>
        <Row>
          <Col>
            <div className="d-flex flex-wrap align-items-center justify-content-between">
              <h5 className='title my-3 me-4'><span>Ensure</span> Dashboard</h5>
              <div className="d-flex flex-wrap align-items-center justify-content-end">
                <FiSearch className='fs-20 me-lg-5 me-4' />
                <FiBell className='fs-20 me-lg-5 me-4' />
                <div className="avatar me-3" style={{ backgroundImage: 'url(' + avatar + ')' }}></div>
                <p className="mb-0 f-black">{user?.data?.first_name + " " + user?.data?.last_name}</p>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Topbar;
