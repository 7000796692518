import * as types from "../actionTypes";

const initialState = {
  testReducer: false,
  cardData: {},
  AllCardData: {},
  loading: false,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action) => {
  switch (action.type) {
    // case types.TEST:
    //   return {
    //     testReducer: action.payload,
    //   };
    case types.CARD_DETAILS:
      // console.log("get all card reducer --> ", action.payload);
      return {
        ...state,
        AllCardData: action.payload,
      };
    case types.CARD_ADD_DETAILS:
      // console.log("card add reducer --> ", action.payload);
      return {
        ...state,
        cardData: action.payload,
      };
    case types.CARD_UPDATE:
      // console.log("card update reducer --> ", action.payload);
      return {
        ...state,
        cardData: action.payload,
      };
    default:
      return state;
  }
};
