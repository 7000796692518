import React from "react";
import { progress } from '../assets/images/index.js';
import '../assets/style/style.scss';
import {Container, Row, Col, Image, Button } from 'react-bootstrap';
import { HeaderWhite } from "../component";

class About extends React.Component {
  render() {
    return (
      <>
        <HeaderWhite />
        <div id="about">
          <Container>
            <Row>
              <Col lg={6} md={7}>
                <div className="left">
                  <div className="content">
                  <h1 className="title display-5 mb-md-5">Do you know where <br /> your <span>emails</span> are going?</h1>
                  <div className="text-center">
                    <Image className="progress-circle d-md-none mb-5" src={progress} fluid/>
                  </div>
                  <h4 className="mb-4">Did you know nost emails ends up in spam folders?</h4>
                  <p className="mb-5">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Tempor dictum suspendisse 
                    tincidunt imperdiet. Facilisi ac lectus lobortis duis laoreet id feugiat. 
                    Lorem aliquam scelerisque nec tortor non in viverra fringilla.</p>
                    <Button variant="green" size="lg">Sign up now and increase your odds</Button>
                  </div>
                </div>
              </Col>
              <Col lg={6} md={5} className="d-none d-md-block">
                <Image  src={progress} fluid/>
              </Col>
            </Row>
          </Container>
          <div className="company-watermark o-60">
            <p>By <a href="/">Eugeniuses</a></p>
          </div>
        </div>
      </>
    );
  }
}
export default About;
