import React from "react";
import { bar_graph, line_graph } from '../../assets/images/index.js';
import { Sidebar, Topbar } from '../../component/index.js';
import '../../assets/style/style.scss';
import { Container, Row, Col, Card, Form, Button, Table, Image } from 'react-bootstrap';
import { FiActivity, FiArrowRight, FiLogOut } from "react-icons/fi";
import { AiOutlineWarning } from "react-icons/ai";

class Dashboard extends React.Component {
  render() {
    return (
      <>
        <div id="dashboard-main" className="bg-theme-gradient flex-row">
          <Sidebar />
          <div className="content px-md-5 px-2 pt-3">
            <Topbar />
            <Container fluid>
              <Card className="border-0 px-5 pt-5">
                <Row>
                  <Col xl={8}>
                    <div className="d-flex align-items-center mb-4">
                      <FiActivity className="me-3 f-green-2 fs-20" />
                      <h5 className="fs-18 mb-0">Analytics</h5>
                    </div>
                    <Row>
                      <Col xl={8} lg={6} className="mb-5">
                        <Card className="border-0 shadow-2 h-100">
                          <Card.Header className="d-flex justify-content-between align-items-center bg-white-gradient border-0 p-4">
                            <h6 className="mb-0 f-blue">Performance</h6>
                            <span className="badge bg-green-100 f-green-second br-10 fs-16 fw-normal px-3 py-2">1 Year</span>
                          </Card.Header>
                          <Card.Body className="p-4">
                            <Image src={line_graph} fluid />
                          </Card.Body>
                        </Card>
                      </Col>
                      <Col xl={4} lg={6} className="mb-5">
                        <Card className="border-0 shadow-2 h-100">
                          <Card.Header className="d-flex justify-content-between align-items-center bg-white-gradient border-0 p-4">
                            <h6 className="mb-0 f-blue">Contacts in Mailing</h6>
                            <span className="badge bg-green-100 f-green-second br-10 fs-16 fw-normal px-3 py-2">5 Months</span>
                          </Card.Header>
                          <Card.Body className="p-4">
                            <Image src={bar_graph} fluid />
                          </Card.Body>
                        </Card>
                      </Col>
                      <Col xs={12}>
                        <div className="d-flex align-items-center mb-4">
                          <AiOutlineWarning className="me-3 f-green-2 fs-20" />
                          <h5 className="fs-18 mb-0">Reports</h5>
                        </div>
                        <div className="filter-btns mb-4">
                          <Button className="me-3 px-3 active" variant="white">Show all <span>(50)</span></Button>
                          <Button className="me-3 px-3" variant="white">Pending <span>(20)</span></Button>
                          <Button className="me-3 px-3" variant="white">Solved <span>(20)</span></Button>
                        </div>
                        <Row>
                          {Array.from({ length: 3 }).map((_, idx) => (
                            <Col xxl={4} lg={6} className="mb-5">
                              <Card className="border-0 shadow-2 h-100">
                                <Card.Body className="h-100">
                                  <div className="d-flex align-items-center justify-content-between flex-wrap mb-4">
                                    <h6 className="mb-0 f-blue">Report Title</h6>
                                    <span className="badge bg-green-100 f-black br-10 fs-14 fw-normal px-3 py-2">Pending</span>
                                  </div>
                                  <p className="fs-14">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Volutpat tellus cras nisl, eu, mi pellentesque. Eget porttitor at amet  pretium mauris sit varius quis. Et justo, ac mattis fermentum tellus tortor. Turpis eu pulvinar suspendisse adipiscing a tincidunt nisi. Nunc sagittis, tincidunt.</p>
                                </Card.Body>
                              </Card>
                            </Col>
                          ))}
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                  <Col xl={4}>
                    <div className="d-flex align-items-center mb-4">
                      <FiActivity className="me-3 f-green-2 fs-20" />
                      <h5 className="fs-18 mb-0">Your Mailing</h5>
                    </div>
                    <Card className="border-0 shadow-2">
                      <Card.Header className="bg-white-gradient border-0 p-4">
                        <h6 className="mb-4 f-blue">Add email to your listing  <span className="fw-normal fs-14">- (98 contacts)</span></h6>
                        <Form.Group className="mb-4 icon-input">
                          <Form.Control className="py-3" type="text" placeholder="user@email.com" />
                          <Button className="icon-button px-3" variant="link"><FiArrowRight /></Button>
                        </Form.Group>
                        <Button className="px-3 py-2 me-2" variant="light-blue">
                          <FiLogOut className="trn-90 me-2" /> Upload a .csv file
                        </Button>
                        <Button className="f-grey-c2c2c2 py-2" variant="link">
                          <FiLogOut className="trn-90 me-2" /> <span>Template</span>
                        </Button>
                      </Card.Header>
                      <Card.Body className="p-4">
                        <Table className="email-table" responsive>
                          <thead>
                            <tr>
                              <th>Name</th>
                              <th>Email</th>
                            </tr>
                          </thead>
                          <tbody>
                            {Array.from({ length: 10 }).map((_, idx) => (
                              <tr>
                                <td>Tommy Miller</td>
                                <td>tommymiller@email.com</td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
              </Card>
            </Container>
          </div>
        </div>
      </>
    );
  }
}
export default Dashboard;
