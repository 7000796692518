import React from "react";
import { cs_a, cs_b, cs_c, cs_d, cs_e, cs_f, cs_g, cs_h, cs_i, cs_j, cs_k, cs_l } from '../assets/images/index.js';
import '../assets/style/style.scss';
import {Container, Row, Col, Button } from 'react-bootstrap';
import { HeaderWhite } from "../component";

class CaseStudy extends React.Component {
  render() {
    return (
      <>
        <HeaderWhite />
        <div id="case-study">
          <Container>
            <Row>
              <Col xl={5} className="d-none d-xl-block">
                <div className="left">
                  <Row>
                    <Col xs={4}>
                      <div className="case-study-col">
                        <div className="case-study-person" style={{backgroundImage:'url('+cs_a+')'}}></div>
                      </div>
                    </Col>
                    <Col xs={4}>
                      <div className="case-study-col">
                        <div className="case-study-person" style={{backgroundImage:'url('+cs_b+')'}}></div>
                      </div>
                    </Col>
                    <Col xs={4}>
                      <div className="case-study-col">
                        <div className="case-study-person" style={{backgroundImage:'url('+cs_c+')'}}></div>
                      </div>
                    </Col>
                    <Col xs={4}>
                      <div className="case-study-col">
                        <div className="case-study-person" style={{backgroundImage:'url('+cs_d+')'}}></div>
                      </div>
                    </Col>
                    <Col xs={4}>
                      <div className="case-study-col">
                        <div className="case-study-person" style={{backgroundImage:'url('+cs_e+')'}}></div>
                      </div>
                    </Col>
                    <Col xs={4}>
                      <div className="case-study-col">
                        <div className="case-study-person" style={{backgroundImage:'url('+cs_f+')'}}></div>
                      </div>
                    </Col>
                    <Col xs={4}>
                      <div className="case-study-col">
                        <div className="case-study-person" style={{backgroundImage:'url('+cs_g+')'}}></div>
                      </div>
                    </Col>
                    <Col xs={4}>
                      <div className="case-study-col">
                        <div className="case-study-person" style={{backgroundImage:'url('+cs_h+')'}}></div>
                      </div>
                    </Col>
                    <Col xs={4}>
                      <div className="case-study-col">
                        <div className="case-study-person" style={{backgroundImage:'url('+cs_i+')'}}></div>
                      </div>
                    </Col>
                    <Col xs={4}>
                      <div className="case-study-col">
                        <div className="case-study-person" style={{backgroundImage:'url('+cs_j+')'}}></div>
                      </div>
                    </Col>
                    <Col xs={4}>
                      <div className="case-study-col">
                        <div className="case-study-person" style={{backgroundImage:'url('+cs_k+')'}}></div>
                      </div>
                    </Col>
                    <Col xs={4}>
                      <div className="case-study-col">
                        <div className="case-study-person" style={{backgroundImage:'url('+cs_l+')'}}></div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
              <Col xl={6} className="ms-auto">
                <div className="right">
                  <h1 className="display-4 main-title">Case Study</h1>
                  <h5 className="circle-title"><span className="circle"></span> The Client</h5>
                  <div className="d-xl-none mt-5">
                    <Row>
                      <Col xs={4} lg={3}>
                        <div className="case-study-col">
                          <div className="case-study-person" style={{backgroundImage:'url('+cs_a+')'}}></div>
                        </div>
                      </Col>
                      <Col xs={4} lg={3}>
                        <div className="case-study-col">
                          <div className="case-study-person" style={{backgroundImage:'url('+cs_b+')'}}></div>
                        </div>
                      </Col>
                      <Col xs={4} lg={3}>
                        <div className="case-study-col">
                          <div className="case-study-person" style={{backgroundImage:'url('+cs_c+')'}}></div>
                        </div>
                      </Col>
                      <Col xs={4} lg={3}>
                        <div className="case-study-col">
                          <div className="case-study-person" style={{backgroundImage:'url('+cs_d+')'}}></div>
                        </div>
                      </Col>
                      <Col xs={4} lg={3}>
                        <div className="case-study-col">
                          <div className="case-study-person" style={{backgroundImage:'url('+cs_e+')'}}></div>
                        </div>
                      </Col>
                      <Col xs={4} lg={3}>
                        <div className="case-study-col">
                          <div className="case-study-person" style={{backgroundImage:'url('+cs_f+')'}}></div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                  <div className="content">
                    <h5 className="subtitle">The issue</h5>
                    <p className="text">Dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing 
                      elit. Dolor sit amet, consectetur elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                    <h5 className="subtitle">Solution and results</h5>
                    <p className="text">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur 
                      adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, 
                      consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum 
                      dolor sit amet, consectetur adipiscing elit.</p>
                  </div>
                  <Button variant="green" size="lg">Sign up now and increase your odds</Button>
                </div>
              </Col>
            </Row>
          </Container>
          <div className="company-watermark o-60">
            <p>By <a href="/">Eugeniuses</a></p>
          </div>
        </div>
      </>
    );
  }
}
export default CaseStudy;
