import React from "react";
import { } from '../../assets/images/index.js';
import { Sidebar, Topbar, Price, CreditCard } from '../../component/index.js';
import '../../assets/style/style.scss';
import { Container, Row, Col, Card, Form, Button, Modal } from 'react-bootstrap';
import { FiActivity } from "react-icons/fi";
import { AiOutlineWarning } from "react-icons/ai";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { connect } from "react-redux";
import {
  CardAddAction, GetallCardAction, CardUpdateAction
} from "../../redux/actions/cardAction";
import {
  setUserInfo, GetUserInfo, setDomain
} from "../../redux/actions/authAction";
import Toasts from "../../component/Toasts";
import { ToastContainer } from 'react-toastify';

class UserInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      setShow: false,
      fields: {},
      userID: JSON.parse(localStorage.getItem("UID")),
      Userfields: {},
      Domain: {},
      errors: {},
      errorClassName: {},
      month: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sept", "Oct", "Nov", "Dec"],
      year: [],
    }
  }
  componentWillMount() {
    var year = new Date().getFullYear();
    for (var i = year; i <= year + 8; i++) {
      this.state.year.push(i)
    }
  }
  componentDidMount() {
    this.props.GetCards();
  }
  componentDidUpdate(Props) {
    if (this.props.DomainData !== Props.DomainData) {
      if (this.props.DomainData.success == 200) {
        Toasts(this.props.DomainData?.message, "success");
      }
      if (this.props.DomainData.success == 404) {
        Toasts(this.props.DomainData?.message, "error");
      }
    }
    if (this.props.CardData !== Props.CardData) {
      Toasts(this.props.CardData?.message, "success");
      this.handleClose();
      this.props.GetCards();
    }
    if (this.props.UserInfo !== Props.UserInfo) {
      if (this.props.UserInfo) {
        // toast(this.props.UserInfo?.message);
        const filetedata = {
          "last_name": this.props.UserInfo.data?.last_name,
          "first_name": this.props.UserInfo.data?.first_name,
          "email": this.props.UserInfo.data?.email,
          "mobile_no": this.props.UserInfo.data?.mobile_no,
        }
        // console.log("filetedata -->",filetedata);
        this.setState({ Userfields: filetedata })
      }
    }
  }
  handleChange = (field, event) => {
    let fields = this.state.fields;
    fields[field] = event.target.value;
    this.setState({ fields });
  }
  handleUserInfoChange = (field, event) => {
    let Userfields = this.state.Userfields;
    Userfields[field] = event.target.value;
    this.setState({ Userfields });
  }
  handleShow() {
    this.setState({ setShow: true });
  }
  handleClose() {
    this.setState({ setShow: false });
  }
  handUserInfoUpdate = async () => {
    // console.log("card all data -->", this.state.Userfields);
    try {
      await this.props.setUserInformation(this.state.Userfields, this.state.userID);
      this.props.GetUser(this.state.userID);
      // Toasts("user information updated", "success");
    } catch (error) {
      Toasts(error, "error");
    }
  }
  handleValidation() {
    let fields = this.state.fields;
    let errors = {};
    let errorClassName = {};
    let formIsValid = true;

    //card_name
    if (!fields["card_name"]) {
      formIsValid = false;
      errors["card_name"] = "Please enter your card name.";
      errorClassName["card_name"] = "text-danger";
    }
    //card_number
    if (!fields["card_number"]) {
      formIsValid = false;
      errors["card_number"] = "Please enter your card number.";
      errorClassName["card_number"] = "text-danger";
    } else {
      var re16digit = /^\d{16}$/
      if (!re16digit.test(fields["card_number"])) {
        formIsValid = false;
        errors["card_number"] = "Please enter your valid card number.";
        errorClassName["card_number"] = "text-danger";
      }
    }
    //exp_Date
    if (!fields["exp_month"] || !fields["year"]) {
      formIsValid = false;
      errors["year"] = "Please enter your exp_Date.";
      errorClassName["year"] = "text-danger";
    }
    //code
    if (!fields["code"]) {
      formIsValid = false;
      errors["code"] = "Please enter your code.";
      errorClassName["code"] = "text-danger";
    } else {
      var re3digit = /^\d{4}$/
      if (!re3digit.test(fields["code"])) {
        formIsValid = false;
        errors["code"] = "Please enter your valid code.";
        errorClassName["code"] = "text-danger";
      }
    }
    this.setState({ errors: errors });
    this.setState({ errorClassName: errorClassName });
    return formIsValid;
  }
  handleSubmit = async () => {
    let fields = this.state.fields;
    try {
      if (this.handleValidation()) {
        await this.props.CardDetailsAdd(fields)
      }
    } catch (error) {
      Toasts(error, "error");
    }

  };
  handleUpdateCard = async (fields) => {
    await this.props.UpdateCard(fields);
    this.props.GetCards();
  };
  handleDomainChange = (field, event) => {
    let Domain = this.state.Domain;
    Domain[field] = event.target.value;
    this.setState({ Domain });
  }
  addDomain() {
    // console.log("domain -->", this.state.Domain.domain1); 
    try {
      this.props.SetDomain(this.state.Domain.domain1, this.state.userID);
    } catch (error) {
      Toasts(error, "error");
    }
  }
  render() {
    var CreditCardSlider = {
      dots: false,
      arrows: false,
      infinite: false,
      speed: 500,
      variableWidth: true,
      slidesToScroll: 2
    };
    // console.log("user data -->", this.state.Userfields);
    return (
      <>
        <div id="dashboard-main" className="bg-theme-gradient flex-row">
          <Sidebar />
          <div className="content px-md-5 px-2 pt-3">
            <Topbar />
            <Container fluid>
              <Card className="border-0 p-md-5 p-3">
                <Row>
                  <Col xl={6} className="mb-xl-0 mb-4">
                    <div className="d-flex align-items-center mb-4">
                      <FiActivity className="me-3 f-green-2 fs-20" />
                      <h5 className="fs-18 mb-0">Test Inbox</h5>
                    </div>
                    <Card className="border-0 shadow-2 mb-5">
                      <Card.Header className="bg-white-gradient border-0 p-4">
                        <Row>
                          <Col md={8}>
                            <div className="h-100 d-flex align-items-center">
                              <h6 className="mb-md-0 mb-4 fw-500">Set your personal information</h6>
                            </div>
                          </Col>
                          <Col md={4}>
                            <div className="h-100 d-flex align-items-center justify-content-md-end">
                              <Button variant="green" className="px-4 py-2" onClick={this.handUserInfoUpdate}>Update</Button>
                            </div>
                          </Col>
                        </Row>
                      </Card.Header>
                      <Card.Body className="p-4">
                        <Row>
                          <Col md={6}>
                            <Form.Group className="mb-4">
                              <Form.Label className="mb-4 fw-500">First Name</Form.Label>
                              <Form.Control className="control-type-2" type="text" value={this.state.Userfields.first_name}
                                name="first_name" onChange={this.handleUserInfoChange.bind(this, "first_name")}
                                placeholder="Nathalie" />
                            </Form.Group>
                          </Col>
                          <Col md={6}>
                            <Form.Group className="mb-4">
                              <Form.Label className="mb-4 fw-500">Last Name</Form.Label>
                              <Form.Control className="control-type-2" type="text" value={this.state.Userfields.last_name}
                                name="last_name" onChange={this.handleUserInfoChange.bind(this, "last_name")}
                                placeholder="Smith" />
                            </Form.Group>
                          </Col>
                          <Col md={6}>
                            <Form.Group className="mb-4">
                              <Form.Label className="mb-4 fw-500">Email</Form.Label>
                              <Form.Control className="control-type-2" type="email" value={this.state.Userfields.email}
                                name="email" onChange={this.handleUserInfoChange.bind(this, "email")}
                                placeholder="contact@email.com" />
                            </Form.Group>
                          </Col>
                          <Col md={6}>
                            <Form.Group className="mb-4">
                              <Form.Label className="mb-4 fw-500">Mobile nº</Form.Label>
                              <Form.Control className="control-type-2" type="tel" value={this.state.Userfields.mobile_no}
                                name="mobile_no" onChange={this.handleUserInfoChange.bind(this, "mobile_no")}
                                placeholder="000 000 000 000" />
                            </Form.Group>
                          </Col>
                        </Row>
                      </Card.Body>
                    </Card>
                    <div className="d-flex align-items-center mb-4">
                      <AiOutlineWarning className="me-3 f-green-2 fs-20" />
                      <h5 className="fs-18 mb-0">Set your domain</h5>
                    </div>
                    <Card className="border-0 shadow-2">
                      <Card.Header className="bg-white-gradient border-0 p-4">
                        <Row>
                          <Col md={8}>
                            <div className="h-100 d-flex align-items-center">
                              <h6 className="mb-md-0 mb-4 fw-500">Set your personal information</h6>
                            </div>
                          </Col>
                          <Col md={4}>
                            <div className="h-100 d-flex align-items-center justify-content-md-end">
                              <Button variant="green" className="px-4 py-2">Update</Button>
                            </div>
                          </Col>
                        </Row>
                      </Card.Header>
                      <Card.Body className="p-4">
                        <Row>
                          <Col md={6} className="mb-4">
                            <Card className="p-3 border-0 shadow-2 br-1100">
                              <Card.Body>
                                <Form.Group>
                                  <Form.Label className="mb-4 fw-500">Domain name</Form.Label>
                                  <Form.Control className="control-type-2" type="text"
                                    name="domain1" onChange={this.handleDomainChange.bind(this, "domain1")} placeholder="wwww.domain.com" />
                                </Form.Group>
                              </Card.Body>
                            </Card>
                          </Col>
                          <Col md={6} className="mb-4">
                            <Card className="p-3 border-0 shadow-2">
                              <Card.Body>
                                <Form.Group>
                                  <Form.Label className="mb-4 fw-500">Domain name</Form.Label>
                                  <Form.Control className="control-type-2" type="text" placeholder="wwww.domain.com" />
                                </Form.Group>
                              </Card.Body>
                            </Card>
                          </Col>
                          <Col md={6} className="mb-4">
                            <Card className="p-3 border-0 shadow-2">
                              <Card.Body>
                                <Form.Group>
                                  <Form.Label className="mb-4 fw-500">Domain name</Form.Label>
                                  <Form.Control className="control-type-2" type="text" placeholder="wwww.domain.com" />
                                </Form.Group>
                              </Card.Body>
                            </Card>
                          </Col>
                          <Col md={6} className="mb-4">
                            <div className="p-3 add-domain br-1100">
                              <Button variant="green" onClick={this.addDomain.bind(this)}>+ Add a new domain</Button>
                            </div>
                          </Col>
                        </Row>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col xl={6} className="mb-xl-0 mb-4">
                    <div className="d-flex align-items-center mb-4">
                      <FiActivity className="me-3 f-green-2 fs-20" />
                      <h5 className="fs-18 mb-0">Test Inbox</h5>
                    </div>
                    <Card className="border-0 shadow-2">
                      <Card.Header className="bg-white-gradient min-height-92 d-flex align-items-center border-0 p-4">
                        <h6 className="mb-md-0 mb-4 fw-500">Personal information</h6>
                      </Card.Header>
                      <Card.Body className="p-4">
                        <p className="mb-4 fs-14 text-black mb-0">Membership plan</p>
                        <hr />
                        <Row>
                          <Price />
                          <Price />
                          <Price />
                        </Row>
                        <div className="mt-4">
                          <Row>
                            <Col md={8}>
                              <div className="h-100 d-flex align-items-center">
                                <h6 className="mb-md-0 mb-4">Credit card</h6>
                              </div>
                            </Col>
                            <Col md={4}>
                              <div className="h-100 d-flex align-items-center justify-content-md-end">
                                <Button variant="green" onClick={this.handleUpdateCard.bind(this)} className="px-4 py-2">Update</Button>
                              </div>
                            </Col>
                          </Row>
                        </div>
                        <hr className="mb-0" />
                        <Slider className="credit-card-slide" {...CreditCardSlider}>
                          <div className="add-credit-card" onClick={this.handleShow.bind(this)}>
                            <div className="text-center">
                              <span className="plus">+</span>
                              <p className="text">Add a new card</p>
                            </div>
                          </div>
                          {this.props.AllCardData?.data &&
                            this.props.AllCardData?.data.map((data, index) => {
                              return (
                                <>
                                  <CreditCard
                                    CardDetails={data}
                                    handleUpdateCard={this.handleUpdateCard.bind(this)}
                                  />
                                </>
                              )
                            })
                          }
                        </Slider>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
              </Card>
            </Container>
          </div>
        </div>
        <Modal className="add-credit-card-modal" size="lg" show={this.state.setShow} onHide={this.handleClose.bind(this)} centered>
          <Modal.Body>
            <div className="d-flex align-items-center mb-lg-5 mb-4">
              <FiActivity className="me-3 f-green-2 fs-20" />
              <p className="fs-18 text-black mb-0">Add new card</p>
            </div>
            <Form.Group className="mb-4">
              <Form.Label className="mb-4 fw-500 fs-16">Name in the card</Form.Label>
              <Form.Control className="control-type-2" type="text" name="card_name" onChange={this.handleChange.bind(this, "card_name")}
                placeholder="Nathalie Smith" />
              <span className={this.state.errorClassName["card_name"]}>{this.state.errors["card_name"]}</span>
            </Form.Group>
            <Form.Group className="mb-4">
              <Form.Label className="mb-4 fw-500 fs-16">Card Number</Form.Label>
              <Form.Control type="text" name="card_number" onChange={this.handleChange.bind(this, "card_number")}
                className="control-type-2" placeholder="0000 0000 0000 0000" />
              <span className={this.state.errorClassName["card_number"]}>{this.state.errors["card_number"]}</span>
            </Form.Group>
            <Row>
              <Col lg={8}>
                <Form.Group className="mb-lg-5 mb-4">
                  <Form.Label className="mb-4 fw-500 fs-16">Exp. Date</Form.Label>
                  <Row>
                    <Col>
                      <Form.Select className="form-control control-type-2" name="exp_month" onChange={this.handleChange.bind(this, "exp_month")}>
                        <option value="Month" hidden>Month</option> {/* selected disabled  */}
                        {
                          this.state.month.map((value, index) => {
                            return (
                              <option value={index + 1}>{value}</option>
                            )
                          })
                        }
                      </Form.Select>
                    </Col>
                    <Col>
                      <Form.Select className="form-control control-type-2" name="year" onChange={this.handleChange.bind(this, "year")}>
                        <option value="Year" hidden>Year</option>{/* selected disabled  */}
                        {
                          this.state.year.map((value) => {
                            return (
                              <option value={value}>{value}</option>
                            )
                          })
                        }
                      </Form.Select>
                    </Col>
                  </Row>
                </Form.Group>
              </Col>
              <Col lg={4}>
                <Form.Group className="mb-lg-5 mb-4">
                  <Form.Label className="mb-4 fw-500 fs-16">Code</Form.Label>
                  <Form.Control className="control-type-2" type="password" placeholder="Nathalie" name="code" onChange={this.handleChange.bind(this, "code")} />
                  <span className={this.state.errorClassName["code"]}>{this.state.errors["code"]}</span>
                </Form.Group>
              </Col>
              <Col md={4} sm={6}>
                <Button variant="green" className="w-100 py-3" onClick={this.handleSubmit}>Add card</Button>
              </Col>
            </Row>
          </Modal.Body>
        </Modal>
        <ToastContainer />
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  UserInfo: state.auth.UserInfo,
  DomainData: state.auth.DomainData,
  AllCardData: state.cardReducer.AllCardData,
  CardData: state.cardReducer.cardData,
  loading: state.cardReducer.loading,
});

const mapDispatchToProps = (dispatch) => {
  return {
    CardDetailsAdd: (params) => dispatch(CardAddAction(params)),
    setUserInformation: (params, id) => dispatch(setUserInfo(params, id)),
    GetCards: () => dispatch(GetallCardAction()),
    GetUser: (userID) => dispatch(GetUserInfo(userID)),
    UpdateCard: (params, id) => dispatch(CardUpdateAction(params, id)),
    SetDomain: (dom, id) => dispatch(setDomain(dom, id)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(UserInfo);
