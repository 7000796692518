import React from "react";
import { shape_1, shape_2 } from "../assets/images/index.js";
import "../assets/style/style.scss";
import {
  Container,
  Row,
  Col,
  Button,
  Tabs,
  Tab,
  Form,
  Image,
} from "react-bootstrap";
// import { createAccount } from "../api/index";
import { HeaderWhite } from "../component";
import { connect } from "react-redux";
import emailjs from "@emailjs/browser";
import { init } from "@emailjs/browser";
import bcrypt from "bcryptjs";
import { signupAction } from "../redux/actions/authAction.js";
import Toasts from "../component/Toasts";
import { ToastContainer } from "react-toastify";

class CreateAccount extends React.Component {
  constructor() {
    super();
    this.state = {
      ActiveKey: "welcome",
      fields: {},
      errors: {},
      errorClassName: {},
      month: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sept",
        "Oct",
        "Nov",
        "Dec",
      ],
      year: [],
    };
  }
  handleValidation() {
    let fields = this.state.fields;
    let errors = {};
    let errorClassName = {};
    let formIsValid = true;

    //email
    if (!fields["email"]) {
      formIsValid = false;
      errors["email"] = "Please enter your email.";
      errorClassName["email"] = "text-danger";
    } else {
      var regexEmail =
        /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
      if (!regexEmail.test(fields["email"])) {
        formIsValid = false;
        errors["email"] = "Please enter valid email.";
        errorClassName["email"] = "text-danger";
      }
    }
    //domain
    if (!fields["domain"]) {
      formIsValid = false;
      errors["domain"] = "Please enter your domain.";
      errorClassName["domain"] = "text-danger";
    }
    //first_name
    if (!fields["first_name"]) {
      formIsValid = false;
      errors["first_name"] = "Please enter your first name.";
      errorClassName["first_name"] = "text-danger";
    }
    //last_name
    if (!fields["last_name"]) {
      formIsValid = false;
      errors["last_name"] = "Please enter your last name.";
      errorClassName["last_name"] = "text-danger";
    }
    if (this.state.ActiveKey === "payment") {
      //card_name
      if (!fields["card_name"]) {
        formIsValid = false;
        errors["card_name"] = "Please enter your card name.";
        errorClassName["card_name"] = "text-danger";
      }
      //card_number
      if (!fields["card_number"]) {
        formIsValid = false;
        errors["card_number"] = "Please enter your card number.";
        errorClassName["card_number"] = "text-danger";
      } else {
        var re16digit = /^\d{16}$/;
        if (!re16digit.test(fields["card_number"])) {
          formIsValid = false;
          errors["card_number"] = "Please enter your valid card number.";
          errorClassName["card_number"] = "text-danger";
        }
      }
      //exp_Date
      if (!fields["exp_month"] || !fields["year"]) {
        formIsValid = false;
        errors["year"] = "Please enter your exp_Date.";
        errorClassName["year"] = "text-danger";
      }
      //code
      if (!fields["code"]) {
        formIsValid = false;
        errors["code"] = "Please enter your code.";
        errorClassName["code"] = "text-danger";
      } else {
        var re3digit = /^\d{4}$/;
        if (!re3digit.test(fields["code"])) {
          formIsValid = false;
          errors["code"] = "Please enter your valid code.";
          errorClassName["code"] = "text-danger";
        }
      }
    }
    this.setState({ errors: errors });
    this.setState({ errorClassName: errorClassName });
    return formIsValid;
  }
  handleSubmit = async () => {
    let fields = this.state.fields;
    var chars =
      "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
    var passwordLength = 12;
    var RendomPass = "";
    for (var i = 0; i <= passwordLength; i++) {
      var randomNumber = Math.floor(Math.random() * chars.length);
      RendomPass += chars.substring(randomNumber, randomNumber + 1);
    }

    fields["password"] = RendomPass;
    this.setState({ fields });
    if (this.handleValidation()) {
      if (RendomPass) {
        // const serviceID = "service_90t5fto";
        // const templateID = "template_n0rzzfy";
        // const public_key = "6Grd8FbNR555Ujun3";
        const params = {
          name: fields.first_name + " " + fields.last_name,
          message:
            "hello sir your Ensure Delivery website password is given blow",
          code: RendomPass,
          email: fields.email,
        };
        emailjs
          .send(
            process.env.REACT_APP_AC_SERIVICE_ID,
            process.env.REACT_APP_AC_TEMPLATE_ID,
            params,
            process.env.REACT_APP_AC_PUBLIC_KEY
          )
          .then(
            (response) => {
              console.log("MAIL SEND SUCCESS!", response.status, response.text);
            },
            (error) => {
              console.log("FAILED...", error);
            }
          );
      }
      await this.props.signup(this.state.fields);
    }
  };
  handleChange = (field, event) => {
    let fields = this.state.fields;
    fields[field] = event.target.value;
    this.setState({ fields });
  };
  payment() {
    if (this.handleValidation()) {
      this.setState({ ActiveKey: "payment" });
    }
  }
  componentWillMount() {
    var year = new Date().getFullYear();
    for (var i = year; i <= year + 8; i++) {
      this.state.year.push(i);
    }
  }
  componentDidUpdate(Props) {
    if (this.props.userInfo !== Props.userInfo) {
      // console.log("data--->",this.props.userInfo)
      if (this.props.userInfo?.success == 200) {
        Toasts("your account create successfully", "success");
        this.props.navigate("/login");
      }
      if (this.props.userInfo?.status == 400) {
        Toasts("your email alrady exist", "error");
        // this.props.navigate("/login");
      }
    }
  }
  render() {
    return (
      <>
        <HeaderWhite />
        <div id="create-account">
          <Container className="my-3 py-3">
            <h1 className="display-1 title mb-4">
              Create <span>account</span>
            </h1>
            <Tabs
              activeKey={this.state.ActiveKey}
              id="uncontrolled-tab-example"
              className="pb-3 pb-md-5"
              onSelect={(key) => this.setState({ ActiveKey: key })}
            >
              <Tab eventKey="welcome" title="1. Welcome to Ensure Delivery.">
                <div className="advantages">
                  <h5 className="mb-4">Advantages of signing up</h5>
                  <Row>
                    <Col xl={4} md={6} className="mb-3">
                      <p className="advantage-line">
                        Lorem ipsum dolor sit amet
                      </p>
                    </Col>
                    <Col xl={4} md={6} className="mb-3">
                      <p className="advantage-line">
                        Lorem ipsum dolor sit amet
                      </p>
                    </Col>
                    <Col xl={4} md={6} className="mb-3">
                      <p className="advantage-line">
                        Lorem ipsum dolor sit amet
                      </p>
                    </Col>
                    <Col xl={4} md={6} className="mb-3">
                      <p className="advantage-line">
                        Lorem ipsum dolor sit amet
                      </p>
                    </Col>
                    <Col xl={4} md={6} className="mb-3">
                      <p className="advantage-line">
                        Lorem ipsum dolor sit amet
                      </p>
                    </Col>
                    <Col xl={4} md={6} className="mb-3">
                      <p className="advantage-line">
                        Lorem ipsum dolor sit amet
                      </p>
                    </Col>
                  </Row>
                </div>
                <Row>
                  <Col md={11}>
                    <Row>
                      <Col md={6}>
                        <Form.Group className="mb-md-5 mb-3">
                          <Form.Label className="mb-md-4">Domain</Form.Label>
                          <Form.Control
                            type="text"
                            name="domain"
                            onChange={this.handleChange.bind(this, "domain")}
                            placeholder="www.mydomain.com"
                          />
                          <span className={this.state.errorClassName["domain"]}>
                            {this.state.errors["domain"]}
                          </span>
                        </Form.Group>
                      </Col>
                      <Col md={6}>
                        <Form.Group className="mb-md-5 mb-3">
                          <Form.Label className="mb-md-4">Email</Form.Label>
                          <Form.Control
                            type="text"
                            name="email"
                            onChange={this.handleChange.bind(this, "email")}
                            placeholder="- Type here -"
                          />
                          <span className={this.state.errorClassName["email"]}>
                            {this.state.errors["email"]}
                          </span>
                        </Form.Group>
                      </Col>
                      <Col md={6}>
                        <Form.Group className="mb-md-5 mb-3">
                          <Form.Label className="mb-md-4">
                            First Name
                          </Form.Label>
                          <Form.Control
                            type="text"
                            name="first_name"
                            onChange={this.handleChange.bind(
                              this,
                              "first_name"
                            )}
                            placeholder="- Type here -"
                          />
                          <span
                            className={this.state.errorClassName["first_name"]}
                          >
                            {this.state.errors["first_name"]}
                          </span>
                        </Form.Group>
                      </Col>
                      <Col md={6}>
                        <Form.Group className="mb-md-5 mb-3">
                          <Form.Label className="mb-md-4">Last Name</Form.Label>
                          <Form.Control
                            type="text"
                            name="last_name"
                            onChange={this.handleChange.bind(this, "last_name")}
                            placeholder="- Type here -"
                          />
                          <span
                            className={this.state.errorClassName["last_name"]}
                          >
                            {this.state.errors["last_name"]}
                          </span>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col xl={4} md={5}>
                        <Button
                          className="w-100 mt-md-0 mt-3"
                          variant="green"
                          size="lg"
                          onClick={() => this.payment()}
                        >
                          Payment information
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Tab>
              <Tab eventKey="payment" title="2. Payment">
                <Row>
                  <Col md={11}>
                    <Row>
                      <Col md={6}>
                        <Form.Group className="mb-md-5 mb-3">
                          <Form.Label className="mb-md-4">
                            Name in the card
                          </Form.Label>
                          <Form.Control
                            type="text"
                            name="card_name"
                            onChange={this.handleChange.bind(this, "card_name")}
                            placeholder="- Type here -"
                          />
                          <span
                            className={this.state.errorClassName["card_name"]}
                          >
                            {this.state.errors["card_name"]}
                          </span>
                        </Form.Group>
                      </Col>
                      <Col md={6}>
                        <Form.Group className="mb-md-5 mb-3">
                          <Form.Label className="mb-md-4">
                            Card Number
                          </Form.Label>
                          <Form.Control
                            type="text"
                            name="card_number"
                            onChange={this.handleChange.bind(
                              this,
                              "card_number"
                            )}
                            placeholder="0000 0000 0000 0000"
                          />
                          <span
                            className={this.state.errorClassName["card_number"]}
                          >
                            {this.state.errors["card_number"]}
                          </span>
                        </Form.Group>
                      </Col>
                      <Col md={6}>
                        <Form.Group className="mb-md-5 mb-0">
                          <Form.Label className="mb-md-4">Exp. Date</Form.Label>
                          <Row>
                            <Col xl={5} md={6} xs={6}>
                              <Form.Select
                                className="form-control mb-md-5 mb-3"
                                name="exp_month"
                                onChange={this.handleChange.bind(
                                  this,
                                  "exp_month"
                                )}
                              >
                                <option value="Month" hidden>
                                  Month
                                </option>{" "}
                                {/* selected disabled  */}
                                {this.state.month.map((value, index) => {
                                  return (
                                    <option value={index + 1}>{value}</option>
                                  );
                                })}
                              </Form.Select>
                            </Col>
                            <Col xl={5} md={6} xs={6}>
                              <Form.Select
                                className="form-control mb-md-5 mb-3"
                                name="year"
                                onChange={this.handleChange.bind(this, "year")}
                              >
                                <option value="Year" hidden>
                                  Year
                                </option>
                                {/* selected disabled  */}
                                {this.state.year.map((value) => {
                                  return <option value={value}>{value}</option>;
                                })}
                              </Form.Select>
                            </Col>
                          </Row>
                          <span className={this.state.errorClassName["year"]}>
                            {this.state.errors["year"]}
                          </span>
                        </Form.Group>
                      </Col>
                      <Col xl={2} md={3} xs={12}>
                        <Form.Group className="mb-md-5 mb-3">
                          <Form.Label className="mb-md-4">Code</Form.Label>
                          <Form.Control
                            type="password"
                            name="code"
                            onChange={this.handleChange.bind(this, "code")}
                            placeholder="***"
                          />
                          <span className={this.state.errorClassName["code"]}>
                            {this.state.errors["code"]}
                          </span>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col xl={4} lg={5} md={6}>
                        <Button
                          className="w-100 mt-md-0 mt-3"
                          variant="green"
                          size="lg"
                          onClick={this.handleSubmit}
                        >
                          Pay and create account
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Tab>
            </Tabs>
          </Container>
          <Image src={shape_1} fluid className="shape-1" />
          <Image src={shape_2} fluid className="shape-2" />
          <div className="company-watermark o-60">
            <p>
              By <a href="/">Eugeniuses</a>
            </p>
          </div>
        </div>
        <ToastContainer />
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  userInfo: state.auth.userData,
  loading: state.auth.loading,
});

const mapDispatchToProps = (dispatch) => {
  return {
    signup: (params) => dispatch(signupAction(params)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(CreateAccount);
