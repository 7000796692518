import * as types from "../actionTypes";

const initialState = {
  testReducer: false,
  userData: {},
  UserInfo: {},
  loginUserInfo: "",
  loading: false,
  ForgotPassword: {},
  DomainData: [],
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action) => {
  switch (action.type) {
    case types.TEST:
      return {
        ...state,
        testReducer: action.payload,
      };
    case types.SIGNUP_SUCCESS:
      return {
        ...state,
        userData: action.payload,
      };
    case types.LOGIN_SUCCESS:
      return {
        ...state,
        userData: action.payload,
      };
    case types.FORGOT_PASSWORD:
      return {
        ...state,
        ForgotPassword: action.payload,
      };
    case types.SET_USERINFO:
      return {
        ...state,
        UserInfo: action.payload,
      };
    case types.GET_USERINFO:
      return {
        ...state,
        UserInfo: action.payload,
      };
    case types.SET_DOMAIN:
      return {
        ...state,
        DomainData: action.payload,
      };
    case types.LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    default:
      return state;
  }
};
