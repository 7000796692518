import { logo } from '../../assets/images/index.js';
import { Navbar, Container, Nav, Image, Button } from 'react-bootstrap';
import FeatherIcon from 'feather-icons-react';
import { useNavigate } from "react-router-dom";
import './style.scss';

const HeaderWhite = () => {
  let navigate = useNavigate();
  const createAccount = () => {

  }
  const handleLogin = () => {
    navigate("/login");
  }
  const handleDeliverability = () => {
    navigate("/");
  }
  return (
    <Navbar expand="xl" id="header-white">
      <Container>
        <Navbar.Brand href="/">
          <Image src={logo} fluid />
        </Navbar.Brand>
        <Navbar.Toggle className="pe-0" aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ms-auto align-items-xl-center">
            <Nav.Link onClick={() => handleDeliverability()}>Increase your deliverability</Nav.Link>
            <Nav.Link onClick={() => handleLogin()}>Login</Nav.Link>
            <Button onClick={() => createAccount()} variant="outline-green" className="px-xl-4 py-xl-3 order-xl-1 order-2 mt-xl-0 mt-2">Create Account</Button>
            <Nav.Link href="#fb" className="order-xl-2 order-1"><FeatherIcon className="f-green" icon="facebook" size="18" /></Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default HeaderWhite;
