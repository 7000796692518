import {logo} from '../../assets/images/index.js';
import { Container, Row, Col, Image, Button  } from 'react-bootstrap';
import FeatherIcon from 'feather-icons-react';
import './style.scss';

const Footer = () => {
  return (
    <div id="footer">
      <Container>
        <Row>
          <Col xl={4} lg={3}>
            <div className="footer-col-first">
              <Image className="footer-logo me-3" src={logo} fluid/>
              <a href="{}"><FeatherIcon icon="facebook"/></a>
            </div>
          </Col>
          <Col xl={5} lg={6}>
            <div className="footer-col-second">
              <ul>
                <li><FeatherIcon className="me-2" icon="at-sign"/><a href="{}">contact@email.com</a></li>
                <li><FeatherIcon className="me-1" icon="phone"/><a href="{}">+XXX XXX XXX XXX</a></li>
              </ul>
            </div>
          </Col>
          <Col xl={3} lg={3}>
            <div className="footer-col-third">
              <a className="btn py-xl-3 me-2" href="{}">Login</a>
              <Button variant="outline-green" className="px-xl-4 py-xl-3">Create Account</Button>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Footer;