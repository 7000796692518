import React from "react";
import { logo, girl_b, girl_c, msg_green, msg_orange, msg_red, shape_1, shape_2, shape_3, shape_4 } from '../assets/images/index.js';
import '../assets/style/style.scss';
import { Container, Row, Col, Image, Form, Button, Modal, ProgressBar } from 'react-bootstrap';
import { HeaderWhite, Footer } from "../component";

class Test extends React.Component {
  constructor() {
    super();
    this.state = {
      setShow: false,
    }
  }
  handleShow(){
    this.setState({setShow: true});
  }
  handleClose(){
    this.setState({setShow: false});
  }
  render() {
    return (
      <>
        <HeaderWhite />
        <div id="test">
          <Container>
            <Row>
              <Col lg={6} className="d-flex align-items-center">
                  <div className="content">
                    <h1 className="display-5">Test your deliverability</h1>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit 
                      amet, consectetur adipiscing elit. Lorem dolor sit amet.</p>
                      <Form.Group className="mb-4">
                      <Form.Label className="mb-4">Test your deliverability now:</Form.Label>
                      <Form.Control type="text" placeholder="www.mydomain.com" />
                    </Form.Group>
                    <Button className="mt-2" variant="green" size="lg" onClick={this.handleShow.bind(this)}>Test my deliverability</Button>
                  </div>
              </Col>
              <Col lg={5} className="ms-auto text-center">
                <div className="human">
                  <Image src={girl_b} fluid/>
                </div>
              </Col>
            </Row>
          </Container>
          <div className="company-watermark o-60">
            <p>By <a href="/">Eugeniuses</a></p>
          </div>
          <Image src={shape_1} fluid className="shape-1"/>
          <Image src={shape_2} fluid className="shape-2"/>
          <Image src={shape_3} fluid className="shape-3 d-sm-none"/>
          <Image src={shape_4} fluid className="shape-4"/>
        </div>
        <Footer />
        <Modal className="progress-modal" show={this.state.setShow} onHide={this.handleClose.bind(this)} size="lg" centered>
          <Modal.Body>
            <Row>
              <Col lg={7}>
                <Image className="mb-5" src={logo} fluid />
                <ProgressBar  className="mb-3"  now={60} />
                <div className="d-flex align-items-center justify-content-between">
                  <h5 className="time">Scanning domain   |   15 sec left</h5>
                  <h5 className="count"><b>45%</b></h5>
                </div>
              </Col>
              <Col lg={5} className="text-end">
                <Image className="human" src={girl_c} fluid/>
              </Col>
            </Row>
          </Modal.Body>
        </Modal>
        {/* <Modal className="msg-email" show={this.state.setShow} onHide={this.handleClose.bind(this)} size="lg" centered>
          <Modal.Body>
            <div className="top">
              <Image className="mb-5" src={logo} fluid />
            </div>
            <div className="center">
              <Row>
                <Col lg={5} className="text-lg-start text-center">
                  <Image src={msg_orange} fluid />
                </Col>
                <Col lg={7} className="text-lg-start text-center">
                  <h5 className="mt-4 mb-3">Very good!</h5>
                  <ul className="msg-points">
                    <li>Lorem ipsum dolor sit amet</li>
                    <li>Lorem ipsum dolor sit amet</li>
                    <li>Lorem ipsum dolor sit amet</li>
                  </ul>
                </Col>
              </Row>
            </div>
            <div className="bottom">
              <Row>
                <Col lg={7}>
                  <Form.Group className="mb-lg-5 mb-4">
                    <Form.Label className="mb-lg-4 mb-2">Email</Form.Label>
                    <Form.Control type="text" placeholder="www.mydomain.com" />
                  </Form.Group>
                  <Button className="mt-2" variant="green" size="lg">Send it to my email</Button>
                </Col>
                <Col lg={5} className="d-lg-block d-none">
                  <Image className="human" src={girl_c} fluid/>
                </Col>
              </Row>
            </div>
          </Modal.Body>
        </Modal> */}
      </>
    );
  }
}
export default Test;
