import React from "react";
import { } from '../../assets/images/index.js';
import { Sidebar, Topbar } from '../../component/index.js';
import '../../assets/style/style.scss';
import { Container, Row, Col, Card, Form, Button, Table } from 'react-bootstrap';
import { FiActivity, FiUser, FiStar } from "react-icons/fi";
import DataTable, { createTheme } from 'react-data-table-component';
import { connect } from "react-redux";
import {
  setUserInfo, GetUserInfo
} from "../../redux/actions/authAction";

createTheme('solarized', {
  text: {
    primary: '#A09EB9',
    secondary: '#0B0732',
  },

  divider: {
    default: '#EBEBEB',
  },

  context: {
    background: '#826f8d',
    text: '#ec8282',
  }
});

const BootyCheckbox = props => (
  <Form.Check className="table-selector-star" label="" type="checkbox" {...props} />
)

const columns = [
  {
    name: 'Assunto',
    selector: row => row.assunto,
  },
  {
    name: 'Correspondentes',
    selector: row => row.correspondentes,
  },
  {
    name: 'Date',
    selector: row => row.date,
  },
  {
    name: 'Data',
    selector: row => row.data,
  },
];

const data = [
  {
    id: 1,
    assunto: 'Tommy Miller',
    correspondentes: 'tommymiller@email.com',
    date: '28/Fev/21',
    data: 'View stats',
  },
  {
    id: 2,
    assunto: 'Tommy Miller',
    correspondentes: 'tommymiller@email.com',
    date: '28/Fev/21',
    data: 'View stats',
  },
];

class TestInbox extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      setShow: false,
      fields: {},
      userID: JSON.parse(localStorage.getItem("UID")),
    }
  }
  render() {
    console.log("card all data -->", this.props.UserInfo?.data?.email);
    return (
      <>
        <div id="dashboard-main" className="bg-theme-gradient flex-row">
          <Sidebar />
          <div className="content px-md-5 px-2 pt-3">
            <Topbar />
            <Container fluid>
              <Card className="border-0 p-5">
                <div className="d-flex align-items-center mb-4">
                  <FiActivity className="me-3 f-green-2 fs-20" />
                  <h5 className="fs-18 mb-0">Test Inbox</h5>
                </div>
                <Card className="border-0 shadow-2">
                  <Card.Header className="bg-white-gradient border-0 px-5 py-4">
                    <Row>
                      <Col xl={8} lg={6}>
                        <h6>How to?</h6>
                        <p className="mb-lg-0">You can send your emails and see how the behave in this text inbox.</p>
                      </Col>
                      <Col xl={4} lg={6}>
                        <div className="h-100 d-flex align-items-center justify-content-lg-end">
                          <span className="bg-light-blue p-3 br-10">
                            <FiUser className="me-2 fs-20" />
                            {this.props.UserInfo?.data?.email}
                          </span>
                        </div>
                      </Col>
                    </Row>
                  </Card.Header>
                  <Card.Body className="px-5 py-4">
                    <DataTable
                      theme="solarized"
                      columns={columns}
                      data={data}
                      selectableRows={true}
                      selectableRowsComponent={BootyCheckbox}
                      highlightOnHover={true}
                      selectableRowsHighlight={true}
                    />
                  </Card.Body>
                </Card>
              </Card>
            </Container>
          </div>
        </div>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  UserInfo: state.auth.UserInfo,
});

const mapDispatchToProps = (dispatch) => {
  return {
    GetUser: (userID) => dispatch(GetUserInfo(userID)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(TestInbox);

