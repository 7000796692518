export const TEST = "TEST";
// SIGN UP
export const SIGNUP_SUCCESS = "SIGNUP_SUCCESS";
export const SIGNUP_ERROR = "SIGNUP_ERROR";
//Login
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_ERROR = "LOGIN_ERROR";
export const LOADING = "LOADING";
export const SET_USERINFO = "SET_USERINFO";
export const GET_USERINFO = "GET_USERINFO";
export const SET_DOMAIN = "SET_DOMAIN";

export const FORGOT_PASSWORD = "FORGOT_PASSWORD";
export const USER_DETAILS = "USER_DETAILS";

// card 
export const CARD_DETAILS = "CARD_DETAILS";
export const CARD_ADD_DETAILS = "CARD_ADD_DETAILS";
export const CARD_UPDATE = "CARD_UPDATE"
