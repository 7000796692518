import React from "react";
import {girl_a, shape_1, shape_2, shape_3, shape_4} from '../assets/images/index.js';
import '../assets/style/style.scss';
import {Container, Row, Col, Image, Form, Button } from 'react-bootstrap';
import { HeaderTransparent } from "../component";
import { connect } from "react-redux";
// import {
  
// } from "../../redux/actions/cardAction";

class Home extends React.Component {
  render() {
    return (
      <>
        <HeaderTransparent />
        <div id="home" className="d-flex align-items-end">
          <Container>
            <Row>
              <Col xl={5}>
                <div className="content pb-xl-5">
                  <div className="top">
                  <h1 className="display-1 title mb-4">Your emails <span>delivered.</span></h1>
                  <h4>The best route for your emails to be read.</h4>
                  </div>
                  <div className="bottom">
                    <Form.Group className="mb-4">
                      <Form.Label className="mb-4">Test your deliverability now:</Form.Label>
                      <Form.Control type="text" placeholder="www.mydomain.com" />
                    </Form.Group>
                    <Button className="mt-2" variant="green" size="lg">Test my deliverability</Button>
                  </div>
                </div>
              </Col>
              <Col xl={7} className="d-flex align-items-end pe-lg-5">
                <Image className="human-image" src={girl_a} fluid/>
              </Col>
            </Row>
          </Container>
          <Image src={shape_1} fluid className="shape-1"/>
          <Image src={shape_2} fluid className="shape-2"/>
          <Image src={shape_3} fluid className="shape-3"/>
          <Image src={shape_4} fluid className="shape-4"/>
          <div className="company-watermark">
            <p>By <a href="/">Eugeniuses</a></p>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
    
});

const mapDispatchToProps = (dispatch) => {
  return {
    // CardDetailsAdd: (params) => dispatch(CardAddAction(params)),    
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Home);

