import * as types from "../actionTypes";

export const CardAddAction = (field) => async (dispatch) => {
  // console.log("fields -> ", field);
  try {
    dispatch({ type: types.LOADING, payload: true });
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        card_name: field.card_name,
        number: field.card_number,
        exp_month: field.exp_month,
        exp_year: field.year,
        cvc: field.code,
        isActive: false,
      }),
    };
    // console.log("body ---> ", requestOptions.body)
    await fetch(
      `${process.env.REACT_APP_PUBLIC_URL}/CreditCard/add_credit_card`,
      requestOptions
    )
      .then(function (response) {
        return response.json();
      })
      .then(function (body) {
        dispatch({ type: types.CARD_ADD_DETAILS, payload: body });
        dispatch({ type: types.LOADING, payload: false });
      });
  } catch (error) {
    dispatch({ type: types.LOADING, payload: false });
  }
};
// export const CardAddAction = (field) => async (dispatch) => {
//   console.log("card ---> ", field)
//   try {
//     dispatch({ type: types.LOADING, payload: true });
//     const requestOptions = {
//       method: "POST",
//       headers: { "Content-Type": "application/json" },
//       body: {
//         card_name: field.card_name,
//         number: field.card_number,
//         exp_month: field.exp_month,
//         exp_year: field.year,
//         cvc: field.code,
//         isActive: false,
//       },
//     };
//     console.log("body ---> ", requestOptions.body)
//     await fetch(
//       `${process.env.REACT_APP_PUBLIC_URL}/CreditCard/add_credit_card`,
//       requestOptions
//     )
//       .then(function (response) {
//         return response.json();
//       })
//       .then(function (body) {
//         dispatch({ type: types.CARD_ADD_DETAILS, payload: body });
//         dispatch({ type: types.LOADING, payload: false });
//       });
//   } catch (error) {
//     dispatch({ type: types.LOADING, payload: false });
//   }
// };
export const GetallCardAction = () => async (dispatch) => {
  try {
    dispatch({ type: types.LOADING, payload: true });
    const requestOptions = {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    };
    await fetch(
      `${process.env.REACT_APP_PUBLIC_URL}/CreditCard/getall_credit_card`,
      requestOptions
    )
      .then(function (response) {
        return response.json();
      })
      .then(function (data) {
        dispatch({ type: types.CARD_DETAILS, payload: data });
        dispatch({ type: types.LOADING, payload: false });
      });
  } catch (error) {
    dispatch({ type: types.LOADING, payload: false });
  }
};
// export const CardUpdateAction = (field) => async (dispatch) => {
//   console.log("update data ---> ", field)
//   try {
//     dispatch({ type: types.LOADING, payload: true });
//     const requestOptions = {
//       method: "POST",
//       headers: { "Content-Type": "application/json" },
//       body: JSON.stringify({
//         id: field._id,
//         card_name: field.card_name,
//         number: field.card_number,
//         exp_month: field.exp_month,
//         exp_year: field.year,
//         cvc: field.code,
//         isActive: field.isActive,
//       }),
//     };    
//     await fetch(
//       `${process.env.REACT_APP_PUBLIC_URL}/CreditCard/update_credit_card`,
//       requestOptions
//     )
//       .then(function (response) {
//         return response.json();
//       })
//       .then(function (body) {
//         dispatch({ type: types.CARD_UPDATE, payload: body });
//         dispatch({ type: types.LOADING, payload: false });
//       });
//   } catch (error) {
//     dispatch({ type: types.LOADING, payload: false });
//   }
// };
export const CardUpdateAction = (field) => async (dispatch) => {
  try {
    dispatch({ type: types.LOADING, payload: true });
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        id: field._id,
        card_name: field.card_name,
        number: field.card_number,
        exp_month: field.exp_month,
        exp_year: field.year,
        cvc: field.code,
        isActive: field.isActive,
      }),
    };
    // console.log("body -> ", requestOptions);
    await fetch(
      `${process.env.REACT_APP_PUBLIC_URL}/CreditCard/update_credit_card`,
      requestOptions
    )
      .then(function (response) {
        // console.log("res -> ", response);
        return response.json();
      })
      .then(function (body) {
        // console.log("res -> ", body);
        dispatch({ type: types.FORGOT_PASSWORD, payload: body });
        dispatch({ type: types.LOADING, payload: false });
      });
  } catch (error) {
    dispatch({ type: types.LOADING, payload: false });
  }
};