import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import auth from "./reducers/authReducer";
import cardReducer from "./reducers/cardReducer";
// reducer list
const rootReducer = combineReducers({
  auth,
  cardReducer,
});
// store
const store = createStore(rootReducer, undefined, applyMiddleware(thunk));
export default store;