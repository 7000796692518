import { credit_card_bg, visa } from '../../assets/images/index.js';
import { Row, Col, Form, Image } from 'react-bootstrap';
import './style.scss';
import Toasts from "../../component/Toasts";
import { ToastContainer } from 'react-toastify';
// import { useDispatch, useSelector } from "react-redux";
// import { useEffect } from 'react';
// import {
//   CardUpdateAction
// } from "../../redux/actions/cardAction";

const CreditCard = ({ handleUpdateCard, CardDetails }) => {
  // const dispatch = useDispatch();
  // const isLoading = useSelector((state) => state.cardReducer.loading);
  // const Cards = useSelector((state) => state.cardReducer.AllCardData);
  const handleActiveChange = async (data) => {
    try {
      await handleUpdateCard(data);
      Toasts("card updated", "success");
    } catch (error) {
      Toasts(error, "error");
    }
  };
  return (
    <>
      <div className='credit-card' style={{ backgroundImage: 'url(' + credit_card_bg + ')' }}>
        <Image className="mb-4" src={visa} fluid />
        <div className="card-input-main">
          <Row>
            <Col md={7}>
              <div className="card-input">
                <p className='card-input-label'>Name</p>
                <p className='card-input-data'>{CardDetails?.card_name}</p>
              </div>
            </Col>
            <Col md={5}>
              <div className="card-input">
                <p className='card-input-label'>Status</p>
                <Form.Check
                  type="switch"
                  id="custom-switch"
                  label="Active"
                  onChange={() => handleActiveChange(CardDetails)}
                  checked={CardDetails?.isActive}
                />
              </div>
            </Col>
            <Col md={7}>
              <div className="card-input">
                <p className='card-input-label'>Number</p>
                <p className='card-input-data'>{CardDetails?.card_number}</p>
              </div>
            </Col>
            <Col md={5}>
              <div className="card-input">
                <p className='card-input-label'>Exp Date</p>
                <p className='card-input-data'>{CardDetails?.exp_month}/{CardDetails?.exp_year}</p>
              </div>
            </Col>
          </Row>
        </div>
      </div>
      <ToastContainer />
    </>
  );
}

export default CreditCard;
