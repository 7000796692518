import { Col, Card, ListGroup, Button } from 'react-bootstrap';
import './style.scss';

const Price = () => {
  return (
    <Col xxl={4} md={6} className="mb-4">
      <Card className="p-4 border-0 shadow-2 price-card br-10">
        <p className="mb-0 fs-18 text-black">This is</p>
        <h1 className="fst-italic fw-500 mb-4">Basic</h1>
        <ListGroup as="ul" className='mb-4'>
          <ListGroup.Item as="li">Lorem ipsum</ListGroup.Item>
          <ListGroup.Item as="li">Lorem ipsum</ListGroup.Item>
          <ListGroup.Item as="li">Lorem ipsum</ListGroup.Item>
        </ListGroup>
        <h1 className='plan-price'>$15<sub>/mo</sub></h1>
        <Button variant="green" className='w-100 py-3 mt-auto'>Current plan</Button>
      </Card>
    </Col>
  );
}

export default Price;
