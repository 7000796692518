import React from "react";
import { girl_a, logo, shape_1, shape_2, shape_3, shape_4 } from '../../assets/images/index.js';
import '../../assets/style/style.scss';
import { Container, Row, Col, Card, Image, Form, Button } from 'react-bootstrap';
import { connect } from "react-redux";
import {
  LoginAction,
} from "../../redux/actions/authAction.js";
import Toasts from "../../component/Toasts";
import { ToastContainer } from 'react-toastify';

class LogIn extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: {},
      errors: {},
      errorClassName: {},
    }
  }
  componentDidMount() {
  }
  handleValidation() {
    let fields = this.state.fields;
    let errors = {};
    let errorClassName = {};
    let formIsValid = true;
    //email
    if (!fields["email"]) {
      formIsValid = false;
      errors["email"] = "Please enter your email.";
      errorClassName["email"] = "text-danger";
    } else {
      var regexEmail =
        /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
      if (!regexEmail.test(fields["email"])) {
        formIsValid = false;
        errors["email"] = "Please enter valid email.";
        errorClassName["email"] = "text-danger";
      }
    }
    this.setState({ errors: errors });
    this.setState({ errorClassName: errorClassName });
    return formIsValid;
  }
  handleChange = (field, event) => {
    let fields = this.state.fields;
    fields[field] = event.target.value;
    this.setState({ fields });
  }
  handleSubmit = async () => {
    // console.log("fields --> ", this.state.fields)    
    try {
      if (this.handleValidation()) {
        await this.props.signin(this.state.fields)
        Toasts("login sucessfully", "success");
      }
    } catch (error) {
      Toasts(error, "error");
    }
  };
  componentDidUpdate() {
    // console.log("user --> ", this.props.User);
    if (this.props.User?.status == 400) {
      this.props.navigate("/");
    }
  }
  handleForgotPasswordClick = () => {
    this.props.navigate("/forgot-password");
  }
  handleCreateClick = () => {
    this.props.navigate('/createaccount');
  }
  render() {
    return (
      <>
        <div id="log-in-forgot" className="d-flex align-items-center">
          <Container>
            <Card className="br-30 box-shadow-1">
              <Card.Body className="p-lg-5 p-4">
                <Row>
                  <Col lg={6}>
                  </Col>
                  <Col lg={6}>
                    <div className="mb-lg-5 mb-4">
                      <Image className="" src={logo} fluid />
                    </div>
                    <h1 className="display-1 title mb-3"> Hello, <span>Welcome!</span></h1>
                    <Form.Group className="mb-4">
                      <Form.Label className="mb-3" >Email</Form.Label>
                      <Form.Control type="text" name="email" onChange={this.handleChange.bind(this, "email")} placeholder="my.gmail.com" />
                      <span className={this.state.errorClassName["email"]}>{this.state.errors["email"]}</span>
                    </Form.Group>
                    <Form.Group className="mb-4">
                      <Form.Label className="mb-3">Password</Form.Label>
                      <Form.Control type="password" name="password" onChange={this.handleChange.bind(this, "password")} placeholder="- Type here -" />
                    </Form.Group>
                    <Button className="mb-4" variant="green" size="lg" onClick={this.handleSubmit}>Login</Button>
                    <Button className="mb-2 d-table p-0" onClick={this.handleForgotPasswordClick} variant="link">Forgot password?</Button>
                    <Button className="d-table p-0" onClick={this.handleCreateClick} variant="link">Create Account</Button>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Container>
          <Image className="girl d-none d-lg-block" src={girl_a} fluid />
          <Image src={shape_1} fluid className="shape-1" />
          <Image src={shape_2} fluid className="shape-2" />
          <Image src={shape_3} fluid className="shape-3" />
          <Image src={shape_4} fluid className="shape-4" />
          <Image src={shape_3} fluid className="shape-5" />
        </div>
        <ToastContainer />
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  User: state.auth.userData,
  loading: state.auth.loading,
});

const mapDispatchToProps = (dispatch) => {
  return {
    signin: (params) => dispatch(LoginAction(params)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(LogIn);
