import React from "react";
import { } from '../assets/images/index.js';
import '../assets/style/style.scss';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { HeaderWhite, Testimonials } from "../component";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

var testimonial_slider_settings = {
  dots: false,
  arrows: false,
  slidesToShow: 1,
  slidesToScroll: 1,
  infinite: true,
  touchThreshold: 100,
  swipeToSlide: true,
  centerMode: false,
  centerPadding: "0",
  cssEase: "linear",
  responsive: [
    {
      breakpoint: 991,
      settings: {
        centerMode: true,
        centerPadding: "0px",
      }
    }
  ]
};
class Testimonial extends React.Component {
  render() {
    return (
      <>
        <HeaderWhite />
        {/* <Image className="progress-circle d-md-none mb-5" src={progress} fluid/> */}
        <div id="testimonial">
          <Container className="top d-lg-none mb-4">
            <Row>
              <Col>
                  <h1 className="display-5 mb-4">What people talk <br /> about us</h1>
                  <p className="mb-0">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor 
                    sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet.</p>
              </Col>
            </Row>
          </Container>
          <Container>
            <Slider {...testimonial_slider_settings} className="testimonial-carousel">
              <Testimonials />
              <Testimonials />
              <Testimonials />
              <Testimonials />
            </Slider>
          </Container>
          <Container className="bottom">
            <Row>
              <Col>
                <div className="d-none d-lg-block">
                  <h1 className="display-5 mb-4">What people talk about us</h1>
                  <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor 
                    <br /> sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet.</p>
                </div>
                <Button className="mt-lg-5" variant="green" size="lg">Sign up now and increase your odds</Button>
              </Col>
            </Row>
          </Container>
          <div className="company-watermark o-60">
            <p>By <a href="/">Eugeniuses</a></p>
          </div>
        </div>
      </>
    );
  }
}
export default Testimonial;
