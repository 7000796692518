import React from "react";
import ReactDOM from "react-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/style/style.scss";
import { BrowserRouter as Router } from "react-router-dom";
import Routers from "./routers";
import store from "./redux/store";
import {Provider} from 'react-redux'

ReactDOM.render(
  <Provider store={store}>
    <React.StrictMode>
      <Router>
        <Routers />
      </Router>
    </React.StrictMode>
  </Provider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
